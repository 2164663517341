<template>
  <div class="top-nav-wrap" :class="{ sub: isSubNav }">
    <div v-if="title" class="title">{{ $t(title) }}</div>
    <div class="tab-block">
      <div class="tab-warp" v-for="item in navList" :key="item.navTitle">
        <div
          class="tab"
          :class="{ sub: isSubNav, 'select-tab': item.comp === value }"
          @click="$emit('goTo', item.comp)"
        >
          <img class="icon" v-if="item.icon" :src="item.icon" alt="" />
          <span>{{ $t(item.navTitle) }}</span>
        </div>
        <span class="dev" v-if="item.dev">{{ '(開發中)' }}</span>
        <img
          v-if="item.helper"
          class="helper"
          src="@/assets/icons/info.svg"
          alt=""
          @click="$emit('openHelper', item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'TopNav',
  components: {},
  props: {
    title: {
      type: String,
      default: null
    },
    navList: {
      type: Array, // [{}]
      default() {
        return null
      }
    },
    value: {
      type: [String, Number],
      default: null
    },
    isSubNav: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeDestroy() {}
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.top-nav-wrap {
  grid-area: navbar;
  display: flex;
  align-items: center;
  padding-left: px2rem(20);
  height: px2rem($NavH);
  min-height: px2rem($SubNavH);
  background: #151b35;

  &.sub {
    border-top: 1px solid $color_FFF_20;
    height: px2rem($SubNavH);
  }
}

.title {
  font-size: px2rem(32);
  font-weight: 700;
  // line-height: px2rem($NavH);
  color: #ffffff;
  padding-right: px2rem(48);
}

.tab-block {
  display: flex;
  // overflow-x: auto;

  .tab-warp {
    $TabFontSize: px2rem(20);
    $ColorNormal: $color_FFF_50;
    $ColorHover: $color_FFF;
    $ColorActived: $color_FFC600;
    $ColorDisable: $color_FFF_20;
    $UnderLine: 5;

    display: flex;
    align-items: baseline;
    margin-right: px2rem(40);

    &:last-child {
      margin-right: unset;
    }
    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;

      line-height: px2rem($NavH - $UnderLine);
      font-size: $TabFontSize;
      // font-weight: 500;
      color: $ColorNormal;
      user-select: none;
      border-bottom: px2rem($UnderLine) solid transparent; // 0.3125rem solid transparent;
      cursor: pointer;

      img {
        margin-right: 0.5rem;
        width: $TabFontSize;
        height: $TabFontSize;
        @include filter_FFF_50;
      }

      &.sub {
        line-height: px2rem($SubNavH - $UnderLine);
        font-size: px2rem(16);
      }

      &.select-tab {
        color: $ColorActived;
        border-color: $ColorActived;
        // transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        img {
          @include filter_unset;
          @include filter_FFC600;
        }
      }

      &:not(.select-tab):hover {
        color: $ColorHover;
        img {
          @include filter_FFF;
        }
      }
    }

    .helper {
      margin-left: 0.5rem;
      width: px2rem(16);
      height: px2rem(16);
      cursor: pointer;
      @include filter_FFF_50;

      &:hover {
        @include filter_FFF;
      }
    }

    .dev {
      color: $color_FFF_50;
    }
  }
}
</style>
